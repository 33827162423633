import styled, { css } from 'styled-components';
import { Shadow } from '../../assets/images';
import {
  ChestSprite,
  Key_Sprite,
  Key_Sprite_Full,
  Points_1000_Sprite,
  Points_250_Sprite,
  Points_500_Sprite
} from './../../assets/sprites';

export const ChestWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    max-width: 1200px;
    margin: 0 auto;

    ${mediaQueries.sm} {
      position: static;
    }
  `;
});

export const ChestRow = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    height: 28vw;
    max-height: 365px;
    margin: 50px auto;

    * {
      &:focus-visible,
      &:focus {
        border: 0;
        outline: none;
      }
    }

    ${mediaQueries.sm} {
      height: 85vw;
      max-height: initial;
    }
  `;
});

export const ChestInner = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    width: 100%;
    height: 100%;

    ${mediaQueries.sm} {
    }
  `;
});

export const ChestBox = styled.div<{ shadow: boolean }>(({ theme, shadow }) => {
  const { mediaQueries } = theme;

  return css`
    width: 400px;
    height: 365px;
    transition: all 500ms;

    position: absolute;
    top: 0;
    transform: translateX(0);

    @media (max-width: 1250px) {
      width: 31vw;
      height: 28vw;
    }

    &.ChestBox1 {
      left: 0;

      &.hover {
        transform: translateX(10px);
      }

      ${mediaQueries.sm} {
        left: 50%;
        margin-left: -25%;
      }
    }

    &.ChestBox2 {
      left: calc((100% - 400px * 3) / 2 + 400px);

      @media (max-width: 1250px) {
        left: calc((100% - 31vw * 3) / 2 + 31vw);
      }

      ${mediaQueries.sm} {
        margin-left: 0;
        right: initial;
        top: 40vw;
        left: 0;
      }
    }

    &.ChestBox3 {
      right: 0;

      &.hover {
        transform: translateX(-10px);
      }

      ${mediaQueries.sm} {
        top: 40vw;
      }
    }

    &.not-hover {
      transform: scale(0.8);
      opacity: 0.8;

      img {
        cursor: default;
      }
    }

    ${mediaQueries.sm} {
      width: 50%;
      height: 38vw;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      background-image: url('${Shadow}');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 25%;
      transition: opacity 300ms;
      opacity: ${() => !shadow ? 1 : 0};
    }

    img {
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;

      &:focus {
        outline: none !important;
      }
    }

    video, img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  `;
});

export const ChestAnimation = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    z-index: -1;
    width: 400px;
    height: 365px;
    transform: translate(0, 0);
    transition: all 300ms;

    @media (max-width: 1250px) {
      width: 31vw;
      height: 28vw;
    }

    &.active-chest-animation-1 {
      top: 0;
      left: 10px;
      margin-top: 0;
      z-index: 2;

      &.completed-chest-animation-1 {
        @media (min-width: 1250px) {
          left: calc((1200px - 890px) / 2);
        }
        left: calc((100% - 890px) / 2 + (890px / 2 - 31vw) / 2);
        top: 50%;
        margin-top: -200px;
      }
    }

    &.active-chest-animation-2 {
      top: 0;
      left: calc((100% - 400px * 3) / 2 + 400px);
      z-index: 2;

      @media (max-width: 1250px) {
        left: calc((100% - 31vw * 3) / 2 + 31vw);
      }

      &.completed-chest-animation-2 {
        @media (min-width: 1250px) {
          left: calc((1200px - 890px) / 2);
        }
        left: calc((100% - 890px) / 2 + (890px / 2 - 31vw) / 2);
        top: 50%;
        margin-top: -200px;
        margin-left: 0;
      }
    }

    &.active-chest-animation-3 {
      right: 10px;
      top: 0;
      margin-top: 0;
      z-index: 2;

      &.completed-chest-animation-3 {
        @media (min-width: 1250px) {
          right: calc(100% - (1200px - 890px) / 2 - 400px);
        }

        right: calc(50% + (890px / 2 - 31vw) / 2);
        top: 50%;
        margin-top: -200px;
      }
    }

    ${mediaQueries.sm} {
      width: 50vw;
      height: 38vw;

      &.active-chest-animation-1,
      &.active-chest-animation-2,
      &.active-chest-animation-3 {
        top: 50%;
        left: 50%;
        margin-left: -25%;
        margin-top: -50%;
        animation-name: scaleIn;
        animation-duration: 800ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;

        &.completed-chest-animation-1,
        &.completed-chest-animation-2,
        &.completed-chest-animation-3 {
          top: 30px;
          margin-top: 0;
        }

        &.completed-chest-animation-1 {
          left: 50%;
        }

        &.completed-chest-animation-2 {
          left: 50%;
          margin-left: -25%;
        }

        &.hide-chest-animation-mob {
          top: 30%;
          margin-top: 0;
          animation-name: opacityOut;
          animation-duration: 200ms;
        }
      }

      @keyframes scaleIn {
        from {
          transform: scale(0);
          opacity: 0;
        }
        to {
          transform: scale(1);
          opacity: 1;
        }
      }

      @keyframes opacityOut {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }
    }

    img {
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:focus {
        outline: none !important;
      }
    }

    video, img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  `;
});

const countFramesChestX = 4; // from chest_spritesheet.png
const countFramesChestY = 4; // from chest_spritesheet.png

const countFramesKeyX = 6; // from key_spritesheet.png
const countFramesKeyY = 3; // from key_spritesheet.png

const countFramesKeyFullX = 6; // from key_spritesheet.png
const countFramesKeyFullY = 4; // from key_spritesheet.png

const countFramesPoints1000X = 7; // from 1000p_spritesheet.png
const countFramesPoints1000Y = 3; // from 1000p_spritesheet.png

const countFramesPoints500X = 6; // from 500p_spritesheet.png
const countFramesPoints500Y = 3; // from 500p_spritesheet.png

const countFramesPoints250X = 6; // from 250p_spritesheet.png
const countFramesPoints250Y = 3; // from 250p_spritesheet.png

const imgWidth = '400px';
const imgHeight = '275px';

const imgWidthMd = '31vw';
const imgHeightMd = '31vw / (800 / 550)';

const imgWidthSm = '50vw';
const imgHeightSm = '34.375vw';

const timeAnim = '2s';

export const PointsIOS1000 = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    top: 0;
    left: 0;
    width: ${imgWidth};
    height: ${imgHeight};
    background-image: url('${ChestSprite}');
    background-size: calc(${imgWidth} * ${countFramesChestX}) calc(${imgHeight} * ${countFramesChestY});
    animation: chestX ${timeAnim} steps(${countFramesChestX - 1}) 1 forwards,
    chestY ${timeAnim} steps(${countFramesChestY - 1}) 1 forwards;

    @keyframes chestX {
      0% {
        background-position-x: 0;
      }
      100% {
        background-position-x: calc(-${imgWidth} * (${countFramesChestX} - 1));
      }
    }

    @keyframes chestY {
      0% {
        background-position-y: 0;
      }
      100% {
        background-position-y: calc(-${imgHeight} * (${countFramesChestY} - 1));
      }
    }

    @media (max-width: 1250px) {
      width: ${imgWidthMd};
      height: calc(${imgHeightMd});
      background-size: calc(${imgWidthMd} * ${countFramesChestX}) calc(${imgHeightMd} * ${countFramesChestY});

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthMd} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightMd} * (${countFramesChestY} - 1));
        }
      }
    }

    ${mediaQueries.sm} {
      width: ${imgWidthSm};
      height: ${imgHeightSm};
      background-size: calc(${imgWidthSm} * ${countFramesChestX}) calc(${imgHeightSm} * ${countFramesChestY});

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthSm} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightSm} * (${countFramesChestY} - 1));
        }
      }
    }
    
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: ${imgWidth};
      height: ${imgHeight};
      background-image: url('${Key_Sprite_Full}');
      background-size: calc(${imgWidth} * ${countFramesKeyFullX}) calc(${imgHeight} * ${countFramesKeyFullY});
      animation: key_full_X ${timeAnim} steps(${countFramesKeyFullX - 1}) ${timeAnim} 1 forwards,
      key_full_Y ${timeAnim} steps(${countFramesKeyFullY - 1}) ${timeAnim} 1 forwards;

      @keyframes key_full_X {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesKeyFullX} - 1));
        }
      }

      @keyframes key_full_Y {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesKeyFullY} - 1));
        }
      }

      @media (max-width: 1250px) {
        width: ${imgWidthMd};
        height: calc(${imgHeightMd});
        background-size: calc(${imgWidthMd} * ${countFramesKeyFullX}) calc(${imgHeightMd} * ${countFramesKeyFullY});

        @keyframes key_full_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthMd} * (${countFramesKeyFullX} - 1));
          }
        }

        @keyframes key_full_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightMd} * (${countFramesKeyFullY} - 1));
          }
        }
      }

      ${mediaQueries.sm} {
        width: ${imgWidthSm};
        height: ${imgHeightSm};
        background-size: calc(${imgWidthSm} * ${countFramesKeyFullX}) calc(${imgHeightSm} * ${countFramesKeyFullY});

        @keyframes key_full_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthSm} * (${countFramesKeyFullX} - 1));
          }
        }

        @keyframes key_full_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightSm} * (${countFramesKeyFullY} - 1));
          }
        }
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: ${imgWidth};
      height: ${imgHeight};
      background-image: url('${Points_1000_Sprite}');
      background-size: calc(${imgWidth} * ${countFramesPoints1000X}) calc(${imgHeight} * ${countFramesPoints1000Y});
      animation: points_1000_X ${timeAnim} steps(${countFramesPoints1000X - 1}) calc(${timeAnim} * 2) 1 forwards,
      points_1000_Y ${timeAnim} steps(${countFramesPoints1000Y - 1}) calc(${timeAnim} * 2) 1 forwards;

      @keyframes points_1000_X {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesPoints1000X} - 1));
        }
      }

      @keyframes points_1000_Y {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesPoints1000Y} - 1));
        }
      }

      @media (max-width: 1250px) {
        width: ${imgWidthMd};
        height: calc(${imgHeightMd});
        background-size: calc(${imgWidthMd} * ${countFramesPoints1000X}) calc(${imgHeightMd} * ${countFramesPoints1000Y});

        @keyframes points_1000_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthMd} * (${countFramesPoints1000X} - 1));
          }
        }

        @keyframes points_1000_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightMd} * (${countFramesPoints1000Y} - 1));
          }
        }
      }

      ${mediaQueries.sm} {
        width: ${imgWidthSm};
        height: ${imgHeightSm};
        background-size: calc(${imgWidthSm} * ${countFramesPoints1000X}) calc(${imgHeightSm} * ${countFramesPoints1000Y});

        @keyframes points_1000_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthSm} * (${countFramesPoints1000X} - 1));
          }
        }

        @keyframes points_1000_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightSm} * (${countFramesPoints1000Y} - 1));
          }
        }
      }
    }
  `;
});

export const PointsIOS500 = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    top: 0;
    left: 0;
    width: ${imgWidth};
    height: ${imgHeight};
    background-image: url('${ChestSprite}');
    background-size: calc(${imgWidth} * ${countFramesChestX}) calc(${imgHeight} * ${countFramesChestY});
    animation: chestX ${timeAnim} steps(${countFramesChestX - 1}) 1 forwards,
    chestY ${timeAnim} steps(${countFramesChestY - 1}) 1 forwards;

    @keyframes chestX {
      0% {
        background-position-x: 0;
      }
      100% {
        background-position-x: calc(-${imgWidth} * (${countFramesChestX} - 1));
      }
    }

    @keyframes chestY {
      0% {
        background-position-y: 0;
      }
      100% {
        background-position-y: calc(-${imgHeight} * (${countFramesChestY} - 1));
      }
    }

    @media (max-width: 1250px) {
      width: ${imgWidthMd};
      height: calc(${imgHeightMd});
      background-size: calc(${imgWidthMd} * ${countFramesChestX}) calc(${imgHeightMd} * ${countFramesChestY});

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthMd} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightMd} * (${countFramesChestY} - 1));
        }
      }
    }

    ${mediaQueries.sm} {
      width: ${imgWidthSm};
      height: ${imgHeightSm};
      background-size: calc(${imgWidthSm} * ${countFramesChestX}) calc(${imgHeightSm} * ${countFramesChestY});

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthSm} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightSm} * (${countFramesChestY} - 1));
        }
      }
    }
    
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: ${imgWidth};
      height: ${imgHeight};
      background-image: url('${Key_Sprite_Full}');
      background-size: calc(${imgWidth} * ${countFramesKeyFullX}) calc(${imgHeight} * ${countFramesKeyFullY});
      animation: key_full_X ${timeAnim} steps(${countFramesKeyFullX - 1}) ${timeAnim} 1 forwards,
      key_full_Y ${timeAnim} steps(${countFramesKeyFullY - 1}) ${timeAnim} 1 forwards;

      @keyframes key_full_X {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesKeyFullX} - 1));
        }
      }

      @keyframes key_full_Y {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesKeyFullY} - 1));
        }
      }

      @media (max-width: 1250px) {
        width: ${imgWidthMd};
        height: calc(${imgHeightMd});
        background-size: calc(${imgWidthMd} * ${countFramesKeyFullX}) calc(${imgHeightMd} * ${countFramesKeyFullY});

        @keyframes key_full_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthMd} * (${countFramesKeyFullX} - 1));
          }
        }

        @keyframes key_full_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightMd} * (${countFramesKeyFullY} - 1));
          }
        }
      }

      ${mediaQueries.sm} {
        width: ${imgWidthSm};
        height: ${imgHeightSm};
        background-size: calc(${imgWidthSm} * ${countFramesKeyFullX}) calc(${imgHeightSm} * ${countFramesKeyFullY});

        @keyframes key_full_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthSm} * (${countFramesKeyFullX} - 1));
          }
        }

        @keyframes key_full_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightSm} * (${countFramesKeyFullY} - 1));
          }
        }
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: ${imgWidth};
      height: ${imgHeight};
      background-image: url('${Points_500_Sprite}');
      background-size: calc(${imgWidth} * ${countFramesPoints500X}) calc(${imgHeight} * ${countFramesPoints500Y});
      animation: points_500_X ${timeAnim} steps(${countFramesPoints500X - 1}) calc(${timeAnim} * 2) 1 forwards,
      points_500_Y ${timeAnim} steps(${countFramesPoints500Y - 1}) calc(${timeAnim} * 2) 1 forwards;

      @keyframes points_500_X {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesPoints500X} - 1));
        }
      }

      @keyframes points_500_Y {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesPoints500Y} - 1));
        }
      }

      @media (max-width: 1250px) {
        width: ${imgWidthMd};
        height: calc(${imgHeightMd});
        background-size: calc(${imgWidthMd} * ${countFramesPoints500X}) calc(${imgHeightMd} * ${countFramesPoints500Y});

        @keyframes points_500_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthMd} * (${countFramesPoints500X} - 1));
          }
        }

        @keyframes points_500_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightMd} * (${countFramesPoints500Y} - 1));
          }
        }
      }

      ${mediaQueries.sm} {
        width: ${imgWidthSm};
        height: ${imgHeightSm};
        background-size: calc(${imgWidthSm} * ${countFramesPoints500X}) calc(${imgHeightSm} * ${countFramesPoints500Y});

        @keyframes points_500_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthSm} * (${countFramesPoints500X} - 1));
          }
        }

        @keyframes points_500_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightSm} * (${countFramesPoints500Y} - 1));
          }
        }
      }
    }
  `;
});

export const PointsIOS250 = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    top: 0;
    left: 0;
    width: ${imgWidth};
    height: ${imgHeight};
    background-image: url('${ChestSprite}');
    background-size: calc(${imgWidth} * ${countFramesChestX}) calc(${imgHeight} * ${countFramesChestY});
    animation: chestX ${timeAnim} steps(${countFramesChestX - 1}) 1 forwards,
    chestY ${timeAnim} steps(${countFramesChestY - 1}) 1 forwards;

    @keyframes chestX {
      0% {
        background-position-x: 0;
      }
      100% {
        background-position-x: calc(-${imgWidth} * (${countFramesChestX} - 1));
      }
    }

    @keyframes chestY {
      0% {
        background-position-y: 0;
      }
      100% {
        background-position-y: calc(-${imgHeight} * (${countFramesChestY} - 1));
      }
    }

    @media (max-width: 1250px) {
      width: ${imgWidthMd};
      height: calc(${imgHeightMd});
      background-size: calc(${imgWidthMd} * ${countFramesChestX}) calc(${imgHeightMd} * ${countFramesChestY});

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthMd} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightMd} * (${countFramesChestY} - 1));
        }
      }
    }

    ${mediaQueries.sm} {
      width: ${imgWidthSm};
      height: ${imgHeightSm};
      background-size: calc(${imgWidthSm} * ${countFramesChestX}) calc(${imgHeightSm} * ${countFramesChestY});

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthSm} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightSm} * (${countFramesChestY} - 1));
        }
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: ${imgWidth};
      height: ${imgHeight};
      background-image: url('${Key_Sprite_Full}');
      background-size: calc(${imgWidth} * ${countFramesKeyFullX}) calc(${imgHeight} * ${countFramesKeyFullY});
      animation: key_full_X ${timeAnim} steps(${countFramesKeyFullX - 1}) ${timeAnim} 1 forwards,
      key_full_Y ${timeAnim} steps(${countFramesKeyFullY - 1}) ${timeAnim} 1 forwards;

      @keyframes key_full_X {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesKeyFullX} - 1));
        }
      }

      @keyframes key_full_Y {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesKeyFullY} - 1));
        }
      }

      @media (max-width: 1250px) {
        width: ${imgWidthMd};
        height: calc(${imgHeightMd});
        background-size: calc(${imgWidthMd} * ${countFramesKeyFullX}) calc(${imgHeightMd} * ${countFramesKeyFullY});

        @keyframes key_full_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthMd} * (${countFramesKeyFullX} - 1));
          }
        }

        @keyframes key_full_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightMd} * (${countFramesKeyFullY} - 1));
          }
        }
      }

      ${mediaQueries.sm} {
        width: ${imgWidthSm};
        height: ${imgHeightSm};
        background-size: calc(${imgWidthSm} * ${countFramesKeyFullX}) calc(${imgHeightSm} * ${countFramesKeyFullY});

        @keyframes key_full_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthSm} * (${countFramesKeyFullX} - 1));
          }
        }

        @keyframes key_full_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightSm} * (${countFramesKeyFullY} - 1));
          }
        }
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: ${imgWidth};
      height: ${imgHeight};
      background-image: url('${Points_250_Sprite}');
      background-size: calc(${imgWidth} * ${countFramesPoints250X}) calc(${imgHeight} * ${countFramesPoints250Y});
      animation: points_250_X ${timeAnim} steps(${countFramesPoints250X - 1}) calc(${timeAnim} * 2) 1 forwards,
      points_250_Y ${timeAnim} steps(${countFramesPoints250Y - 1}) calc(${timeAnim} * 2) 1 forwards;

      @keyframes points_250_X {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesPoints250X} - 1));
        }
      }

      @keyframes points_250_Y {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesPoints250Y} - 1));
        }
      }

      @media (max-width: 1250px) {
        width: ${imgWidthMd};
        height: calc(${imgHeightMd});
        background-size: calc(${imgWidthMd} * ${countFramesPoints250X}) calc(${imgHeightMd} * ${countFramesPoints250Y});

        @keyframes points_250_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthMd} * (${countFramesPoints250X} - 1));
          }
        }

        @keyframes points_250_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightMd} * (${countFramesPoints250Y} - 1));
          }
        }
      }

      ${mediaQueries.sm} {
        width: ${imgWidthSm};
        height: ${imgHeightSm};
        background-size: calc(${imgWidthSm} * ${countFramesPoints250X}) calc(${imgHeightSm} * ${countFramesPoints250Y});

        @keyframes points_250_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthSm} * (${countFramesPoints250X} - 1));
          }
        }

        @keyframes points_250_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightSm} * (${countFramesPoints250Y} - 1));
          }
        }
      }
    }
  `;
});

export const KeyIOS = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    top: 0;
    left: 0;
    width: ${imgWidth};
    height: ${imgHeight};
    background-image: url('${ChestSprite}');
    background-size: calc(${imgWidth} * ${countFramesChestX}) calc(${imgHeight} * ${countFramesChestY});
    animation: chestX ${timeAnim} steps(${countFramesChestX - 1}) 1 forwards,
    chestY ${timeAnim} steps(${countFramesChestY - 1}) 1 forwards;

    @keyframes chestX {
      0% {
        background-position-x: 0;
      }
      100% {
        background-position-x: calc(-${imgWidth} * (${countFramesChestX} - 1));
      }
    }

    @keyframes chestY {
      0% {
        background-position-y: 0;
      }
      100% {
        background-position-y: calc(-${imgHeight} * (${countFramesChestY} - 1));
      }
    }

    @media (max-width: 1250px) {
      width: ${imgWidthMd};
      height: calc(${imgHeightMd});
      background-size: calc(${imgWidthMd} * ${countFramesChestX}) calc(${imgHeightMd} * ${countFramesChestY});

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthMd} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightMd} * (${countFramesChestY} - 1));
        }
      }
    }

    ${mediaQueries.sm} {
      width: ${imgWidthSm};
      height: ${imgHeightSm};
      background-size: calc(${imgWidthSm} * ${countFramesChestX}) calc(${imgHeightSm} * ${countFramesChestY});

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthSm} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightSm} * (${countFramesChestY} - 1));
        }
      }
    }
    
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: ${imgWidth};
      height: ${imgHeight};
      background-image: url('${Key_Sprite}');
      background-size: calc(${imgWidth} * ${countFramesKeyX}) calc(${imgHeight} * ${countFramesKeyY});
      animation: key_X ${timeAnim} steps(${countFramesKeyX - 1}) ${timeAnim} 1 forwards,
      key_Y ${timeAnim} steps(${countFramesKeyY - 1}) ${timeAnim} 1 forwards;

      @keyframes key_X {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesKeyX} - 1));
        }
      }

      @keyframes key_Y {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesKeyY} - 1));
        }
      }

      @media (max-width: 1250px) {
        width: ${imgWidthMd};
        height: calc(${imgHeightMd});
        background-size: calc(${imgWidthMd} * ${countFramesKeyX}) calc(${imgHeightMd} * ${countFramesKeyY});

        @keyframes key_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthMd} * (${countFramesKeyX} - 1));
          }
        }

        @keyframes key_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightMd} * (${countFramesKeyY} - 1));
          }
        }
      }

      ${mediaQueries.sm} {
        width: ${imgWidthSm};
        height: ${imgHeightSm};
        background-size: calc(${imgWidthSm} * ${countFramesKeyX}) calc(${imgHeightSm} * ${countFramesKeyY});

        @keyframes key_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthSm} * (${countFramesKeyX} - 1));
          }
        }

        @keyframes key_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightSm} * (${countFramesKeyY} - 1));
          }
        }
      }
    }
  `;
});