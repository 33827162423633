import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../redux/actions';
import i18n from 'i18next';
import {logOutHandler} from '../profile/ProfileHeader';
import {handoverConstructor} from '../utils/_app-helpers';

/* global EssoWebDeviceBridge */

// const EssoWebDeviceBridge = {
//   debug() {
//     return "";
//   },
//   getScreenId() {
//     return 1024;
//   },
//   getAccessToken() {
//     return "2e851a4ed991770615bf16f10825d42a";
//   },
//   getEmail() {
//     return "";
//   },
//   getLocale() {
//     return "nl_NL"
//   },
//   getRewardLink() {
//     return 'https://www.test-esso-extra.nl//bol-com-cadeaukaarten.html';
//   },
//   getCardNumber() {
//     return ""
//   }
// }

const baseUrl = process.env.PUBLIC_URL;

const screens = [
	{id: 1010, link: `${baseUrl}/#/auth/forgotten-email`},
	{id: 1011, link: `${baseUrl}/#/auth/forgotten-password`, require: 'cardNumberOrEmail'},
	{id: 1012, link: `${baseUrl}/#/why-phone`},
	{id: 1013, link: `${baseUrl}/#/profile/transactions`, require: 'Token'},
	{id: 1014, link: `${baseUrl}/#/profile/cards`, require: 'Token'},
	{id: 1015, link: `${baseUrl}/#/faq`},
	{id: 1016, link: `${baseUrl}/#/fuel-finder`},
	{id: 1017, link: `/checkout/cart/`, require: 'Shop'},
	{id: 1018, link: `${baseUrl}/#/terms`},
	{id: 1019, link: `${baseUrl}/#/save`},
	{id: 1020, link: `${baseUrl}/#/privacy`},
	{id: 1021, require: 'Shop'},
	{id: 1022, link: `${baseUrl}/#/profile/change-phone`, require: 'Token'},
	{id: 1023, link: `${baseUrl}/#/contact-form`},
	{id: 1024, link: `${baseUrl}/#/profile/change-password`, require: 'Token'},
	// {id: 1025, link: `${baseUrl}/#/${i18n.t('hgc-route-path')}`, require: 'Token'},
	{id: 1030, link: `${baseUrl}/#/contact-form`},
	{id: 1031, link: `${baseUrl}/#/e-bike`, require: 'Token'},
	{id: 1032, link: `${baseUrl}/#/${i18n.t('eyc_2023_route_path')}`, require: 'Token'},
	{id: 1025, link: `${baseUrl}/#/${i18n.t('eyc_2023_route_path')}`, require: 'Token'},
];

export const isBridge = () => (typeof EssoWebDeviceBridge !== 'undefined' ? true : false);

const langs = [
	{app: 'fr_be', web: 'fr'},
	{app: 'nl_nl', web: 'nl'},
	{app: 'nl_be', web: 'vl'},
];

const setLanguageFromApp = (langFromApp) =>
	langs.map((lang) => langFromApp.toLowerCase() === lang.app && i18n.changeLanguage(lang.web));

const debugEnabled = () => {
	if (isBridge() && typeof EssoWebDeviceBridge.debug !== 'undefined') {
		return EssoWebDeviceBridge.debug();
	} else {
		return false;
	}
};

const getScreenInfo = () => {
	if (isBridge()) {
		let screenId = EssoWebDeviceBridge.getScreenId();
		let currentScreen = screens.find(function (element) {
			return element.id === screenId;
		});
		return currentScreen;
	} else {
		return false;
	}
};

class MobileBridge extends Component {
	constructor(props) {
		super(props);

		this.state = {
			response: [],
			isBridge: isBridge(),
			screenInfo: {},
			debug: debugEnabled(),
		};
	}

	componentWillMount() {
		let that = this;
		let screenInfo = getScreenInfo();
		if (screenInfo) {
			this.setState({
				screenInfo: screenInfo,
			});
		}

		if (this.state.isBridge && !this.state.debug) {
			//Check, which language we fetch from App and set it on web side, before component mounting
			setLanguageFromApp(EssoWebDeviceBridge.getLocale());
			logOutHandler(null, true);

			if (screenInfo.require === 'cardNumberOrEmail') {
				if (EssoWebDeviceBridge.getCardNumber()) {
					const data = {masterCardNumber: EssoWebDeviceBridge.getCardNumber()};
					this.props.dispatch(actions.addUserData(data));
					localStorage.setItem('cardNumb', EssoWebDeviceBridge.getCardNumber().toString());
					window.location.href = screenInfo.link + '?card';
				} else {
					localStorage.setItem('userEmail', EssoWebDeviceBridge.getEmail());
					window.location.href = screenInfo.link;
				}
			} else if (screenInfo.require === 'Token') {
				if (EssoWebDeviceBridge.getAccessToken()) {
					localStorage.setItem(
						'authToken',
						JSON.stringify({accessToken: EssoWebDeviceBridge.getAccessToken()})
					);
					localStorage.setItem('authorized', true);
					window.location.href = screenInfo.link;
					window.location.reload();
				} else {
					alert('AccessToken is missed');
					window.location.replace('#/auth/login/');
				}
			} else if (screenInfo.require === 'Shop') {
				let rewardLink = EssoWebDeviceBridge.getRewardLink();
				if (typeof rewardLink !== 'undefined') {
					let prepareRewardLink = rewardLink.trim().replace(/^\//, '').toString();
					rewardLink = prepareRewardLink.replace(
						/^(http:\/\/|https:\/\/)?([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?/,
						''
					);
				}
				let url = screenInfo.id === 1021 ? rewardLink : screenInfo.link;
				let cleanedUrl = url.replace(/^\//, '');
				that.setState({
					redirectShop: true,
					shopURL: handoverConstructor(cleanedUrl),
				});
			} else {
				window.location.href = screenInfo.link;
			}
		}
	}

	componentDidMount() {
		let screenInfo = getScreenInfo();
		if (screenInfo) {
			this.setState({
				screenInfo: screenInfo,
			});
		}

		if (this.state.redirectShop) {
			let form = document.getElementById('handover-sso-form');
			form.submit();
		}

		if (screenInfo.id === 1030) {
			const data = {deleteAccount: true};
			this.props.dispatch(actions.addUserData(data));
			window.location.href = screenInfo.link;
		}
	}

	render() {
		return (
			<div style={styleCSS}>
				{this.state.isBridge && this.state.debug && <BridgeDebugInfo />}
				{!this.state.isBridge && <h1>Cannot connect to Bridge</h1>}
				{this.state.redirectShop && (
					<ShopRedirectForm action={this.state.shopURL} token={EssoWebDeviceBridge.getAccessToken()} />
				)}
			</div>
		);
	}
}

const styleCSS = {
	maxWidth: '1000px',
	margin: '50px auto',
};

const BridgeDebugInfo = () => (
	<section>
		<h1>Bridge page debug data:</h1>
		<p>Bridge info:</p>
		<code>
			{Object.getOwnPropertyNames(EssoWebDeviceBridge).map((e) => (
				<p>
					{e} - {EssoWebDeviceBridge[e].call()}{' '}
				</p>
			))}
		</code>
		<p>Extracted data:</p>
		<code>{JSON.stringify(getScreenInfo())}</code>
	</section>
);

const ShopRedirectForm = (props) => {
	return (
		<form id="handover-sso-form" action={props.action} method="POST">
			<input type="hidden" name="auth_key" id="token-input" value={props.token} />
			<button style={{display: 'none'}}></button>
		</form>
	);
};

const MapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
	};
};
export default connect(MapStateToProps)(MobileBridge);
