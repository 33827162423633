import React from 'react';
import { useSelector } from 'react-redux';
import i18n from '../../../../../../config/i18n';
import { Button } from '../../../../../components/shared';
import { Description, Title, Text } from './Intro.styles';

const Intro = () => {
  const keysGold = useSelector((state: any) => state?.transactionsEYC?.gold);
  const isUserWonEYC = useSelector((state: any) => state?.isUserWonEYC);
  // const isDefineWinnerCompleted = useSelector((state: any) => state?.isDefineWinnerCompletedEYC);
  return (
    <>
      <Description>
        {/*no keys*/}
        {!keysGold &&
          <>
            <Title>{i18n.t('eyc_2024_2_title_noTokens')}</Title>
            <Text>{i18n.t('eyc_2024_2_text_noTokens')}</Text>
            <Button backgroundcolor={'#F37427'}
                    text={i18n.t('eyc_2024_2_btn_noTokens')}
                    url={`/${i18n.t('eyc_2023_route_path')}`}>
            </Button>
          </>
        }

        {/*keys*/}
        {/*{(!!keysGold || !isDefineWinnerCompleted) &&*/}
        {!!keysGold &&
          <>
            <Title>{i18n.t('eyc_2024_2_title_tokens')}</Title>
            <Text>{i18n.t('eyc_2024_2_text_tokens')}</Text>
          </>
        }

        {/*win main prize*/}
        {isUserWonEYC &&
          <>
            <Title>{i18n.t('eyc_2024_2_title_win')}</Title>
            <Text>{i18n.t('eyc_2024_2_text_win')}</Text>
            <Button backgroundcolor={'#F37427'}
                    text={i18n.t('eyc_2024_rewards_btn_2')}
                    url={'/rewards/all'}>
            </Button>
          </>
        }
      </Description>
    </>
  );
};

export default Intro;