import React from 'react';
import { useSelector } from 'react-redux';
import { shopLang } from '../../../../../../../config/config';
import i18n from '../../../../../../../config/i18n';
import { Button } from '../../../../../../../redesign/components/shared';
import {
  ButtonWrapper,
  Container,
  Content,
  HeaderImage,
  KeysText,
  KeysWrapper,
  Text,
  Title,
  Wrapper
} from '../EYCHeader.styles';
import { HeaderBoxesNl1, HeaderBoxesFR1 } from '../../../assets/phase1';
import { HeaderBoxesFR2, HeaderBoxesNL2 } from '../../../assets/phase2';
import { KeyBlue, KeyGold } from '../../../assets';

const HeaderLoggedIn = () => {
  const keysBlue = useSelector((state: any) => state?.transactionsEYC?.blue);
  const keysGold = useSelector((state: any) => state?.transactionsEYC?.gold);
  const locale = shopLang.slice(0, 2).toLowerCase();
  const phase = useSelector((state: any) => state?.infoEYC?.name);

  const headerImg = phase === `ey-2024-${locale}-blue` ? (
    shopLang === 'be_fr' ? HeaderBoxesFR1 : HeaderBoxesNl1
  ) : (
    shopLang === 'be_fr' ? HeaderBoxesFR2 : HeaderBoxesNL2
  );

  return (
    <Wrapper phase={phase === `ey-2024-${locale}-blue` ? 1 : 2}>
      <Container>
        <HeaderImage>
          <img src={headerImg} alt='boxes' />
        </HeaderImage>

        <Content>
          <div>
            {
              phase === `ey-2024-${locale}-blue` ?
                <>
                  <Title>{i18n.t('eyc_2024_1_header_title')}</Title>
                  <Text>{i18n.t('eyc_2024_1_header_text')}</Text>
                  {
                    !!keysBlue &&
                    <KeysWrapper>
                      <img src={KeyBlue} alt='key' />
                      {
                        keysBlue === 1 ?
                          <KeysText>{keysBlue}&nbsp;{i18n.t('eyc_2024_1_header_oneToken')}</KeysText> :
                          <KeysText>{keysBlue}&nbsp;{i18n.t('eyc_2024_1_header_tokens')}</KeysText>
                      }
                    </KeysWrapper>
                  }
                </> : (
                  !!keysGold ?
                  <>
                    <Title>{i18n.t('eyc_2024_2_header_title')}</Title>
                    <Text>{i18n.t('eyc_2024_2_header_text')}</Text>
                    <KeysWrapper>
                      <img src={KeyGold} alt='key' />
                      {
                        keysGold === 1 ?
                          <KeysText>{keysGold}&nbsp;{i18n.t('eyc_2024_2_header_oneToken')}</KeysText>:
                          <KeysText>{keysGold}&nbsp;{i18n.t('eyc_2024_2_header_tokens')}</KeysText>
                      }
                    </KeysWrapper>
                  </> :
                  <>
                    <Title>{i18n.t('eyc_2024_2_header_noToken_title')}</Title>
                    <Text>{i18n.t('eyc_2024_2_header_noToken_text')}</Text>
                  </>
              )
            }

            <ButtonWrapper>
              <Button backgroundcolor={'#F37427'}
                      text={!keysGold && phase !== `ey-2024-${locale}-blue` ? i18n.t('eyc_2024_rewards_btn') : i18n.t('eyc_2024_play_btn')}
                      url={!keysGold && phase !== `ey-2024-${locale}-blue` ? '/rewards/all' : '/chest-opening-game'}
              />
            </ButtonWrapper>
          </div>
        </Content>
      </Container>
    </Wrapper>
  )
};

export default HeaderLoggedIn;